import React, { useRef, useEffect } from "react";
import TawkTo from 'tawkto-react'
import Header from "../component/Header";
import Footer from "../component/Footer";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import OurProduct from "./OurProduct";


function HomePage(props) {

    const homeSection = useRef(null);
    const aboutSection = useRef(null);
    const servicesSection = useRef(null);
    const contactSection = useRef(null);
    const productSection = useRef(null);

    useEffect(() => {
        var tawk = new TawkTo("5a4e27bad7591465c7067f4f", "default")
        tawk.onStatusChange((status) => {
            // console.log(status)
        })
    }, [])

    if (props.screenSize === 2) {
        return (
            <>
                <Header screenSize={props.screenSize} homeSection={homeSection} productSection={productSection} aboutSection={aboutSection} servicesSection={servicesSection} contactSection={contactSection} />
                <div className="section section1" ref={homeSection}></div>
                <div className="section section2" ref={aboutSection}><AboutUs screenSize={props.screenSize} /></div>
                <div className="section section3" ref={productSection}><OurProduct screenSize={props.screenSize} /></div>
                <div className="section section4" ref={servicesSection}></div>
                <div className="section section5" ref={contactSection}><ContactUs screenSize={props.screenSize} /></div>
                <Footer screenSize={props.screenSize} />
            </>
        )
    } else {
        return (
            <>
                <Header screenSize={props.screenSize} homeSection={homeSection} productSection={productSection} aboutSection={aboutSection} servicesSection={servicesSection} contactSection={contactSection} />
                <div className="section section1" ref={homeSection}></div>
                <div className="section section2" ref={aboutSection}><AboutUs screenSize={props.screenSize} /></div>
                <div className="section section3" ref={productSection}><OurProduct screenSize={props.screenSize} /></div>
                <div className="section section4" ref={servicesSection}></div>
                <div className="section section5" ref={contactSection}><ContactUs screenSize={props.screenSize} /></div>
                <Footer screenSize={props.screenSize} />
            </>
        )
    }
}

export default HomePage;