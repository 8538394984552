import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import linedinAppLogo from '../asset/linkedin.png';
import metaAppLogo from '../asset/meta.png';
import twitterAppLogo from '../asset/twitter.png';
import Typography from '@mui/material/Typography';
import '../App.css';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'right',
    backgroundColor: '#f7d454',
    color: "#000000"
}))


function Footer(props) {

    const handleHeaderClick = (entityname) => {
        if (entityname === "ln"){
            window.open('https://www.linkedin.com/company/13624252', '_blank')
        } else if (entityname === "fb"){
            window.open('https://www.facebook.com/cue7ven', '_blank')
        } else if (entityname === "tw"){
            window.open('https://twitter.com/cue7ven', '_blank')
        }
    };

    if (props.screenSize === 2) {
        return (
            <>
                <Item>
                    <Grid container >
                        <Grid item xs={7}>
                            <Button onClick={() => handleHeaderClick("ln")}><img className="ln-logo" src={linedinAppLogo} alt="logo" /></Button>
                            <Button onClick={() => handleHeaderClick("fb")}><img className="ln-logo" src={metaAppLogo} alt="logo" /></Button>
                            <Button onClick={() => handleHeaderClick("tw")}><img className="ln-logo" src={twitterAppLogo} alt="logo" /></Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{ marginTop: "8px", fontWeight: "550", textTransform: "inherit", fontSize: "10px", fontFamily: "system-ui" }}>CUE7VEN © {new Date().getFullYear()} | All rights reserved.</Typography>
                        </Grid>
                    </Grid>
                </Item>
            </>
        );
    } else {
        return (
            <>
                <Item>
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            <Button onClick={() => handleHeaderClick("ln")}><img className="ln-logo" src={linedinAppLogo} alt="logo" /></Button>
                            <Button onClick={() => handleHeaderClick("fb")}><img className="ln-logo" src={metaAppLogo} alt="logo" /></Button>
                            <Button onClick={() => handleHeaderClick("tw")}><img className="ln-logo" src={twitterAppLogo} alt="logo" /></Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{ marginTop: "8px", fontWeight: "550", textTransform: "inherit", fontSize: "medium", fontFamily: "system-ui" }}>CUE7VEN © {new Date().getFullYear()} | All rights reserved.</Typography>
                        </Grid>
                    </Grid>
                </Item>
            </>
        );
    }

}


export default Footer;