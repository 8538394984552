import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CommentIcon from '@mui/icons-material/Comment';
import Button from '@mui/material/Button';
import skypeAppLogo from '../asset/skype.png';
import mailAppLogo from '../asset/mail.png';

import '../App.css';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#fff',
    color: "#000000"
}))

function ContactUs(props) {

    const handleHeaderClick = (entityname) => {
        if (entityname === "1") {
            window.open('/AdvertiserPage', '_self')
        } else if (entityname === "2") {
            window.open('/PublisherPage', '_self')
        } else if (entityname === "3") {
            window.open('/TAndCPage', '_self')
        }else if (entityname === "0") {
            window.open('/ServicesPage', '_self')
        }
    };

    if (props.screenSize === 2) {
        return (
            <Item>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>Contact Us</Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                            <img src={mailAppLogo} className="ln-logo" alt="logo" />&nbsp;&nbsp;contact@cue7ven.com
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                            <img src={skypeAppLogo} className="ln-logo" alt="logo" />&nbsp;&nbsp;contact_67119&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>Useful Links</Typography>
                        <Button onClick={() => handleHeaderClick("0")}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                                &nbsp;CUE7VEN Services
                            </Typography>
                        </Button>
                        <Button onClick={() => handleHeaderClick("1")}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                                &nbsp;&nbsp;Advertiser Services
                            </Typography>
                        </Button>
                        <Button onClick={() => handleHeaderClick("2")}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                                &nbsp;&nbsp;Publisher Services
                            </Typography>
                        </Button>
                        <Button onClick={() => handleHeaderClick("3")}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                                &nbsp;&nbsp;Terms & Conditions
                            </Typography>
                        </Button>

                    </Grid>
                    {/* <Grid item xs={10}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>Write to Us</Typography>
                        <TextField sx={{ m: 1, width: "89%" }} color="info" required id="outlined-required" label="Required" placeholder="Please Enter Your Name"
                            InputProps={{ startAdornment: (<InputAdornment position="start"> <AccountCircle /> </InputAdornment>), }} />
                        <TextField sx={{ m: 1, width: "89%" }} color="info" required id="outlined-required" label="Required" placeholder="Please Enter Your Email"
                            InputProps={{ startAdornment: (<InputAdornment position="start"> <EmailOutlinedIcon /> </InputAdornment>), }} />
                        <TextField sx={{ m: 1, width: "89%" }} color="info" id="outlined-multiline-static" label="Message Box" multiline rows={4} placeholder="Please Enter Your Message"
                            InputProps={{ startAdornment: (<InputAdornment position="start"> <CommentIcon /> </InputAdornment>), }} />
                        <Button variant="outlined" sx={{ m: 1, width: "89%" }} color="info">Submit</Button>
                    </Grid> */}
                </Grid>
            </Item>
        );

    } else {
        return (
            <Item>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>Contact Us</Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                            <img src={mailAppLogo} className="ln-logo" alt="logo" />&nbsp;&nbsp;contact@cue7ven.com
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                            <img src={skypeAppLogo} className="ln-logo" alt="logo" />&nbsp;&nbsp;contact_67119&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>Useful Links</Typography>
                        <Button onClick={() => handleHeaderClick("0")}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                                &nbsp;CUE7VEN Services
                            </Typography>
                        </Button>
                        <Button onClick={() => handleHeaderClick("1")}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                                &nbsp;&nbsp;Advertiser Services
                            </Typography>
                        </Button>
                        <Button onClick={() => handleHeaderClick("2")}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                                &nbsp;&nbsp;Publisher Services
                            </Typography>
                        </Button>
                        <Button onClick={() => handleHeaderClick("3")}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: "inline-flex", marginBottom: "-15px", marginLeft: "25px", marginTop: "5px", height: "7.5vh", fontWeight: "300", textTransform: "inherit", fontFamily: "system-ui", fontSize: "15px" }}>
                                &nbsp;&nbsp;Terms & Conditions
                            </Typography>
                        </Button>

                    </Grid>
                    {/* <Grid item xs={5}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>Write to Us</Typography>
                        <TextField sx={{ m: 1, width: "89%" }} color="info" required id="outlined-required" label="Required" placeholder="Please Enter Your Name"
                            InputProps={{ startAdornment: (<InputAdornment position="start"> <AccountCircle /> </InputAdornment>), }} />
                        <TextField sx={{ m: 1, width: "89%" }} color="info" required id="outlined-required" label="Required" placeholder="Please Enter Your Email"
                            InputProps={{ startAdornment: (<InputAdornment position="start"> <EmailOutlinedIcon /> </InputAdornment>), }} />
                        <TextField sx={{ m: 1, width: "89%" }} color="info" id="outlined-multiline-static" label="Message Box" multiline rows={4} placeholder="Please Enter Your Message"
                            InputProps={{ startAdornment: (<InputAdornment position="start"> <CommentIcon /> </InputAdornment>), }} />
                        <Button variant="outlined" sx={{ m: 1, width: "89%" }} color="info">Submit</Button>
                    </Grid> */}
                </Grid>
            </Item>
        );
    }
}


export default ContactUs;