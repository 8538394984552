import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import OurProcess from "../component/OurProcess";
import ContactUs from "../pages/ContactUs";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import b4 from '../asset/b4.png';
import b5 from '../asset/b5.png';
import b6 from '../asset/b6.png';
import b7 from '../asset/b7.png';
import b8 from '../asset/b8.png';
import b9 from '../asset/b9.png';
import Divider from '@mui/material/Divider';


import '../App.css';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#fff',
    color: "#000000",
    fontFamily: "system-ui"
}))



function OurServices(props) {

    if (props.screenSize === 2) {
        return (
            <>
                <Header screenSize={props.screenSize} />
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>OUR SERVICES</Typography>
                        </Grid>


                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "10px", fontSize: "x-large", fontWeight: "550" }}>Display Advertisement</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Display represents the backbone of the digital advertising industry.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> At CUE7VEN we combine our seasoned industry knowledge, advanced ad formats, audience data gathering tools, and high professional standards to ensure we deliver exactly what our supply and demand partners desire.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> In addition we partner with brand safety verification vendors to ensure quality ads and traffic.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> CUE7VEN delivers measure-ability and accountability from clicks to conversions to maximize your ROI & RON.</Typography>

                        </Grid>
                        <Grid item style={{ width: "95%" }}>
                            <Box component="img" sx={{ height: "25vh" }} src={b4} />
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>

                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "center", marginTop: "10px", fontSize: "x-large", fontWeight: "550" }}>Banners Advertisment</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Premium Audience across the globe.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> Pinpointed live targeting</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> Advanced fraud detection</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> Real-time monitoring</Typography>
                            
                        </Grid>
                        <Grid item style={{ width: "95%" }}>
                            <Box component="img" sx={{ height: "25vh" }} src={b5} />
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>


                        
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "10px", fontSize: "x-large", fontWeight: "550" }}>Pop-Up Advertisement</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> A pop under ad format displays as a full size browser window behind the website.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> This is the `money maker` of ad formats due to its high impact and performance for advertiser campaigns.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> For Chrome browsers, this ad displays as a new tab rather than a new window.</Typography>
                            
                        </Grid>
                        <Grid item style={{ width: "95%" }}>
                            <Box component="img" sx={{ height: "25vh" }} src={b6} />
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>

                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "center", marginTop: "10px", fontSize: "x-large", fontWeight: "550" }}>Video Advertisement</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> As video consumption continues to rapidly increase across desktop and mobile devices we have developed technological tools to provide the best possible video solutions across in-stream, in-feed and mobile video</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> We support multiple player formats, including VAST, VPAID, and JavaScript in order to satisfy each of our clients’ unique needs.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> Advanced fraud detection is our key features.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> Real-time monitoring</Typography>
                            
                        </Grid>
                        <Grid item style={{ width: "95%" }}>
                            <Box component="img" sx={{ height: "25vh" }} src={b7} />
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>


                        
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "10px", fontSize: "x-large", fontWeight: "550" }}>Native Advertisement</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Full control, full flexibility & maximum revenue</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> Using zero maintenance widgets to offer native advertising placements that translate into an immediate revenue stream.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> The ad is always visible, increasing performance and productivity.</Typography>
                            
                        </Grid>
                        <Grid item style={{ width: "95%" }}>
                            <Box component="img" sx={{ height: "25vh" }} src={b8} />
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>

                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "center", marginTop: "10px", fontSize: "x-large", fontWeight: "550" }}>E-Mail Advertisment</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left"}}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Delivering the right message at the right time to our most valuable audiences is the key to deepening customer relationships.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left"}}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> By designing smart audience segments and dynamic campaigns, every campaign you send can speak to individual customers.</Typography>
                            
                        </Grid>
                        <Grid item style={{ width: "95%" }}>
                            <Box component="img" sx={{ height: "25vh" }} src={b9} />
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>

                        <Grid item style={{ width: "95%", marginLeft: "10px", marginBottom: "50px" }}>
                            <OurProcess screenSize={props.screenSize} />
                        </Grid>
                    </Grid>
                </Item>
                <ContactUs screenSize={props.screenSize} />
                <Footer screenSize={props.screenSize} />
            </>
        );

    } else {
        return (
            <>
                <Header screenSize={props.screenSize} />
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>OUR SERVICES</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6}>
                                <Box component="img" sx={{ height: "40vh" }} src={b4} />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "50px", fontSize: "x-large", fontWeight: "550" }}>Display Advertisement</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Display represents the backbone of the digital advertising industry.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> At CUE7VEN we combine our seasoned industry knowledge, advanced ad formats, audience data gathering tools, and high professional standards to ensure we deliver exactly what our supply and demand partners desire.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> In addition we partner with brand safety verification vendors to ensure quality ads and traffic.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> CUE7VEN delivers measure-ability and accountability from clicks to conversions to maximize your ROI & RON.</Typography>
                                <Divider variant="middle" sx={{ marginTop: "50px" }} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "center", marginTop: "50px", fontSize: "x-large", fontWeight: "550" }}>Banners Advertisment</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Premium Audience across the globe.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> Pinpointed live targeting</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> Advanced fraud detection</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> Real-time monitoring</Typography>
                                <Divider variant="middle" sx={{ marginTop: "50px" }} />
                            </Grid>
                            <Grid item xs={5}>
                                <Box component="img" sx={{ height: "40vh" }} src={b5} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6}>
                                <Box component="img" sx={{ height: "40vh" }} src={b6} />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "50px", fontSize: "x-large", fontWeight: "550" }}>Pop-Up Advertisement</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> A pop under ad format displays as a full size browser window behind the website.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> This is the `money maker` of ad formats due to its high impact and performance for advertiser campaigns.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> For Chrome browsers, this ad displays as a new tab rather than a new window.</Typography>
                                <Divider variant="middle" sx={{ marginTop: "50px" }} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "center", marginTop: "50px", fontSize: "x-large", fontWeight: "550" }}>Video Advertisement</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> As video consumption continues to rapidly increase across desktop and mobile devices we have developed technological tools to provide the best possible video solutions across in-stream, in-feed and mobile video</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> We support multiple player formats, including VAST, VPAID, and JavaScript in order to satisfy each of our clients’ unique needs.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> Advanced fraud detection is our key features.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> Real-time monitoring</Typography>
                                <Divider variant="middle" sx={{ marginTop: "50px" }} />
                            </Grid>
                            <Grid item xs={5}>
                                <Box component="img" sx={{ height: "40vh" }} src={b7} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6}>
                                <Box component="img" sx={{ height: "40vh" }} src={b8} />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "50px", fontSize: "x-large", fontWeight: "550" }}>Native Advertisement</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Full control, full flexibility & maximum revenue</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> Using zero maintenance widgets to offer native advertising placements that translate into an immediate revenue stream.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> The ad is always visible, increasing performance and productivity.</Typography>

                                <Divider variant="middle" sx={{ marginTop: "50px" }} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "center", marginTop: "50px", fontSize: "x-large", fontWeight: "550" }}>E-Mail Advertisment</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Delivering the right message at the right time to our most valuable audiences is the key to deepening customer relationships.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", paddingLeft: "250px" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> By designing smart audience segments and dynamic campaigns, every campaign you send can speak to individual customers.</Typography>
                                <Divider variant="middle" sx={{ marginTop: "50px" }} />
                            </Grid>
                            <Grid item xs={5}>
                                <Box component="img" sx={{ height: "40vh" }} src={b9} />
                            </Grid>
                        </Grid>
                        <Grid item style={{ width: "85vw", marginLeft: "100px", marginBottom: "50px" }}>
                            <OurProcess screenSize={props.screenSize} />
                        </Grid>
                    </Grid>
                </Item>
                <ContactUs screenSize={props.screenSize} />
                <Footer screenSize={props.screenSize} />
            </>
        );
    }
}


export default OurServices;