import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import OurProcess from "../component/OurProcess";
import ContactUs from "../pages/ContactUs";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import b3 from '../asset/b3.png';
import Divider from '@mui/material/Divider';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


import '../App.css';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#fff',
    color: "#000000",
    fontFamily: "system-ui"
}))



function AboutUs(props) {

    if (props.screenSize === 2) {
        return (
            <>
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>ABOUT US</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>CUE7VEN is a digital advertising technology company that provides programmatic advertising solutions through the use of all adverising formats. In a crowded advertising marketplace, we believe that media partners, publishers and advertisers three two important things: Speed, Reach & Personalized Solutions</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>We believe in technology and embrace it to drive speed and have footprints across the globe. While technology can provide speed and contextual advertising. We are the agency who belive in maintaning relationship on long decades.</Typography>
                        </Grid>
                        <Grid item style={{ width: "95%", marginLeft: "10px" , marginBottom: "20px" }}>
                            <OurProcess screenSize={props.screenSize} />
                        </Grid>
                    </Grid>
                </Item>
            </>
        );

    } else {
        return (
            <>
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>ABOUT US</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>CUE7VEN is a digital advertising technology company that provides programmatic advertising solutions through the use of all adverising formats. In a crowded advertising marketplace, we believe that media partners, publishers and advertisers three two important things: Speed, Reach & Personalized Solutions</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>We believe in technology and embrace it to drive speed and have footprints across the globe. While technology can provide speed and contextual advertising. We are the agency who belive in maintaning relationship on long decades.</Typography>
                        </Grid>
                        <Grid item style={{ width: "85vw", marginLeft: "100px", marginBottom: "50px" }}>
                            <OurProcess screenSize={props.screenSize} />
                        </Grid>
                    </Grid>
                </Item>
            </>
        );
    }
}


export default AboutUs;