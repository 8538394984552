import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import b10 from '../asset/b10.jpg';
import b11 from '../asset/b11.jpg';
import b12 from '../asset/b12.jpg';
import b13 from '../asset/b13.jpg';
import b14 from '../asset/b14.jpg';
import b15 from '../asset/b15.jpg';
import Divider from '@mui/material/Divider';


import '../App.css';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#fff',
    color: "#000000",
    fontFamily: "system-ui"
}))



function OurProduct(props) {

    if (props.screenSize === 2) {
        return (
            <>
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>OUR PRODUCT</Typography>
                        </Grid>
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginBottom: "50px", fontSize: "large", fontWeight: "550" }}>Mobile Applications</Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Box component="img" sx={{ height: "20vh" }} src={b11} />
                            <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>CHRONICLE EASY</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Box component="img" sx={{ height: "20vh" }} src={b12} />
                            <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>FILL BOX</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Box component="img" sx={{ height: "20vh" }} src={b10} />
                            <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>COMING SOON</Typography>
                        </Grid>

                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginBottom: "50px", fontSize: "large", fontWeight: "550", marginTop: "50px" }}>Web Application</Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Box component="img" sx={{ height: "20vh" }} src={b15} />
                            <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>CHRONICLE EASY</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Box component="img" sx={{ height: "20vh" }} src={b14} />
                            <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>FILL BOX</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Box component="img" sx={{ height: "20vh" }} src={b13} />
                            <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>COMING SOON</Typography>
                        </Grid>

                    </Grid>
                    <Divider variant="middle" sx={{ marginTop: "50px" }} />
                </Item>
            </>
        )
    } else {
        return (
            <>
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>OUR PRODUCT</Typography>
                        </Grid>
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginBottom: "50px", fontSize: "x-large", fontWeight: "550" }}>Mobile Applications</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={3}>
                                <Box component="img" sx={{ height: "15vh" }} src={b11} />
                                <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>CHRONICLE EASY</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Box component="img" sx={{ height: "15vh" }} src={b12} />
                                <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>FILL BOX</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Box component="img" sx={{ height: "15vh" }} src={b10} />
                                <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>COMING SOON</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginBottom: "50px", fontSize: "x-large", fontWeight: "550", marginTop: "50px" }}>Web Application</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={3}>
                                <Box component="img" sx={{ height: "17vh" }} src={b15} />
                                <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>CHRONICLE EASY</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Box component="img" sx={{ height: "17vh" }} src={b14} />
                                <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>FILL BOX</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Box component="img" sx={{ height: "17vh" }} src={b13} />
                                <Typography variant="overline" display="block" gutterBottom sx={{ fontFamily: "system-ui" }}>COMING SOON</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" sx={{ marginTop: "50px" }} />
                </Item>
            </>
        );
    }
}


export default OurProduct;