import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import OurProcess from "../component/OurProcess";
import ContactUs from "../pages/ContactUs";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import b2 from '../asset/b2.png';
import Divider from '@mui/material/Divider';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


import '../App.css';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#fff',
    color: "#000000",
    fontFamily: "system-ui"
}))



function Advertiser(props) {

    if (props.screenSize === 2) {
        return (
            <>
                <Header screenSize={props.screenSize} />
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>ADVERTISER</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>CUE7VEN connect with top SSP ensures high quality demand and top payout for each impression. We remove demand fragmentation by connecting you to more than 50 leading DSP partners across all screens and channels.</Typography>
                        </Grid>

                        <Grid item style={{ width: "95%" }}>
                            <Box component="img" sx={{ height: "25vh" }} src={b2} />
                        </Grid>
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "50px", fontSize: "larger", fontWeight: "550" }}>Maximize yield across all channels</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>Experience our technology ­based advertising solutions for optimizing your campaigns and maximizing your ROI. All you have to do is choose. From our amazing self service platform up to our full service solution – select the way you prefer to reach your audiences and lead them to conversion.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> We give our advertisers the best ROI in terms of their campaign objectives be it viewability,reach,CTR,CR,conversion goals.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> Give brand safety control as we work with Forensiq, Moat for brand safety and viewability metrics.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> Best ROI in terms of business goals.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> All inventory is audited manually and confirms to quality standards.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(V) </Box> Full visibilty and control on traffic.</Typography>
                        </Grid>

                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "100px", fontSize: "x-large", fontWeight: "550" }}>Our Work Culture</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Drive yield across all devices including Desktop, Mobile Web, and Mobile App.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Multi-format support for Video, In-App, Display, POP, Banners HTML5, VPAID (Flash and JavaScript), and VAST (2.2,2.3,3.0 and 4.0).</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Support for all video ad types like pre-roll, mid-roll, post-roll, and video banners.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Our four Milestones of our business are sales, reach to right one, visibility and revenue.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> We are a data-driven mobile and desktop advertising company with a focus on performance, catering to advertisers, brands and agencies looking to scale their business with digital advertising.</Typography>
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>
                        <Grid item style={{ width: "95%", marginLeft: "10px", marginBottom: "50px" }}>
                            <OurProcess screenSize={props.screenSize} />
                        </Grid>
                    </Grid>
                </Item>
                <ContactUs screenSize={props.screenSize} />
                <Footer screenSize={props.screenSize} />
            </>
        );

    } else {
        return (
            <>
                <Header screenSize={props.screenSize} />
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>ADVERTISER</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>CUE7VEN connect with top SSP ensures high quality demand and top payout for each impression. We remove demand fragmentation by connecting you to more than 50 leading DSP partners across all screens and channels.</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6}>
                                <Box component="img" sx={{ height: "35vh" }} src={b2} />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "50px", fontSize: "larger", fontWeight: "550" }}>Maximize yield across all channels</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>Experience our technology ­based advertising solutions for optimizing your campaigns and maximizing your ROI. All you have to do is choose. From our amazing self service platform up to our full service solution – select the way you prefer to reach your audiences and lead them to conversion.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> We give our advertisers the best ROI in terms of their campaign objectives be it viewability,reach,CTR,CR,conversion goals.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> Give brand safety control as we work with Forensiq, Moat for brand safety and viewability metrics.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> Best ROI in terms of business goals.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> All inventory is audited manually and confirms to quality standards.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(V) </Box> Full visibilty and control on traffic.</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "100px", fontSize: "x-large", fontWeight: "550" }}>Our Work Culture</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Drive yield across all devices including Desktop, Mobile Web, and Mobile App.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Multi-format support for Video, In-App, Display, POP, Banners HTML5, VPAID (Flash and JavaScript), and VAST (2.2,2.3,3.0 and 4.0).</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Support for all video ad types like pre-roll, mid-roll, post-roll, and video banners.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Our four Milestones of our business are sales, reach to right one, visibility and revenue.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> We are a data-driven mobile and desktop advertising company with a focus on performance, catering to advertisers, brands and agencies looking to scale their business with digital advertising.</Typography>
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>
                        <Grid item style={{ width: "85vw", marginLeft: "100px", marginBottom: "50px" }}>
                            <OurProcess screenSize={props.screenSize} />
                        </Grid>
                    </Grid>
                </Item>
                <ContactUs screenSize={props.screenSize} />
                <Footer screenSize={props.screenSize} />
            </>
        );
    }
}


export default Advertiser;