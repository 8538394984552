import React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import logo from '../asset/logo.png';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import '../App.css';
import { useLocation } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    backgroundColor: '#f7d454',
    color: "#000000",
    position: "sticky",
    top: "0"
}))

const theme1 = createTheme({
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    height: "6.5vh"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: "black",
                    "&:hover": {
                        color: "#00000",
                        backgroundColor: "#fac655",
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    marginTop: "-8px"
                }
            }
        }
    }
})


function Header(props) {

    const location = useLocation();

    const handleHeaderClick = (entityName) => {
        if (location.pathname === '/') {
            window.scrollTo({
                top: entityName.current.offsetTop,
                behavior: 'smooth',
            });
        } else {
            window.open('/', '_self');
        }
    }


    if (props.screenSize === 2) {
        return (
            <ThemeProvider theme={theme1}>
                <Item>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <img src={logo} className="App-logo" alt="logo" onClick={() => handleHeaderClick(props.homeSection)}/>
                        </Grid>
                    </Grid>
                </Item>
            </ThemeProvider>
        );
    } else {
        return (
            <ThemeProvider theme={theme1}>
                <Item>
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            <img src={logo} className="App-logo" alt="logo" onClick={() => handleHeaderClick(props.homeSection)} />
                        </Grid>
                        <Grid item xs={7}>
                            <Stack spacing={2} direction="row">
                                <Button sx={{ color: "#000", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontSize: "medium", fontFamily: "system-ui", letterSpacing: "2px" }} onClick={() => handleHeaderClick(props.homeSection)}>Home</Button>
                                <Button sx={{ color: "#000", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontSize: "medium", fontFamily: "system-ui", letterSpacing: "2px" }} onClick={() => handleHeaderClick(props.aboutSection)}>About</Button>
                                <Button sx={{ color: "#000", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontSize: "medium", fontFamily: "system-ui", letterSpacing: "2px" }} onClick={() => handleHeaderClick(props.productSection)}>Products</Button>
                                <Button sx={{ color: "#000", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontSize: "medium", fontFamily: "system-ui", letterSpacing: "2px" }} onClick={() =>  window.open('/ServicesPage', '_self')}>Services</Button>
                                <Button sx={{ color: "#000", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontSize: "medium", fontFamily: "system-ui", letterSpacing: "2px" }} onClick={() => handleHeaderClick(props.contactSection)}>Contact Us</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Item>
            </ThemeProvider>
        );
    }
}


export default Header;