import React from 'react';
import { Route, Routes, BrowserRouter } from "react-router-dom";

// Pages Import
import HomePage from './pages/HomePage.js';
import TAndC from './pages/TAndC.js';
import Advertiser from './pages/Advertiser.js';
import Publisher from './pages/Publisher.js';
import OurServices from './pages/OurServices.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';

function App(props) {
  const mq = window.matchMedia("(min-width: 300px) and (max-width: 780px)");
  var screenSize = 1;
  if (mq.matches) { screenSize = 2; } else { screenSize = 1; }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/TAndCPage" name="Terms and Conditions Page" element={<TAndC {...props} screenSize={screenSize} />} />
        <Route path="/privacy-policy" name="Privacy Policy Page" element={<PrivacyPolicy {...props} screenSize={screenSize} />} />
        <Route path="/AdvertiserPage" name="Advertiser Service Page" element={<Advertiser {...props} screenSize={screenSize} />} />
        <Route path="/PublisherPage" name="Publisher Service Page" element={<Publisher {...props} screenSize={screenSize} />} />
        <Route path="/ServicesPage" name="CUE7VEN Service Page" element={<OurServices {...props} screenSize={screenSize} />} />
        <Route path="/" name="Home Page" element={<HomePage {...props} screenSize={screenSize} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
