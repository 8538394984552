import React from "react";
import '../App.css';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Divider from '@mui/material/Divider';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ConnectWithoutContactIcon fontSize="large" />,
        2: <AssignmentIcon fontSize="large" />,
        3: <BuildIcon fontSize="large" />,
        4: <RocketLaunchIcon fontSize="large" />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: true,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

function getStepContent(step) {
    switch (step) {
        case 0:
            return `Consult duty with partner not events.`;
        case 1:
            return 'All we need is the plan, the road map, and the courage to press on to our destination.';
        case 2:
            return `We believe in creating the success, The price of success are hard work,dedication to the work and the determination of the results.`;
        default:
            return 'We believe on the best release at best time in the right direction.';
    }
}


const steps = ['Consult', 'Plan', 'Create', 'Release'];


function OurProcess(props) {

    if (props.screenSize === 2) {
        return (
            <>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>OUR PROCESS</Typography>
                <Stepper alternativeLabel activeStep={3} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label} active={true}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}><Typography variant="button">{label}</Typography></StepLabel>
                            <StepContent><Typography variant="caption">{getStepContent(index)}</Typography></StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Divider variant="middle" sx={{ marginTop: "50px" }} />
            </>
        )
    } else {
        return (
            <>
                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "50px", marginBottom: "50px", fontSize: "x-large", fontWeight: "550" }}>Our Process</Typography>
                <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label} active={true}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}><Typography variant="button">{label}</Typography></StepLabel>
                            <StepContent><Typography variant="caption">{getStepContent(index)}</Typography></StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Divider variant="middle" sx={{ marginTop: "50px" }} />
            </>
        );
    }
}


export default OurProcess;