import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import OurProcess from "../component/OurProcess";
import ContactUs from "../pages/ContactUs";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import b3 from '../asset/b3.png';
import Divider from '@mui/material/Divider';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


import '../App.css';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#fff',
    color: "#000000",
    fontFamily: "system-ui"
}))



function Publisher(props) {

    if (props.screenSize === 2) {
        return (
            <>
                <Header screenSize={props.screenSize} />
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>PUBLISHER</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>Our exclusive technological solutions for publishers and digital media channels, provide you with an ideal tool to increase profits, and obtain greater value from your advertising space. With 2 billion ad impressions every day, we are able to guarantee you a best fill rate across all geographic regions.</Typography>
                        </Grid>

                        <Grid item style={{ width: "95%" }}>
                            <Box component="img" sx={{ height: "35vh" }} src={b3} />
                        </Grid>
                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "50px", fontSize: "larger", fontWeight: "550" }}>From our powerful, self ­service platform to our Premium Service of media buying ­ select the option that is best for you – and start to monetize.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Setting floors and manage residual inventory.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> Best fill rates and CPM.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> Quality control in terms of including/excluding categories,brands,technical attributes, specific advertisers, brands.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> Brand safety as we work on themediatrust.com and manage all ads for malware protection, brand safety,etc.</Typography></Grid>

                        <Grid item style={{ width: "95%", marginLeft: "10px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "100px", fontSize: "x-large", fontWeight: "550" }}>Our Work Culture</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Our advertising campaigns cut through the all the clutter of Mobile and Desktop advertising and are centered on performance. We route traffic with direct offers to different markets and segments to deliver the best cost per acquisition/install/sale/Impression performance. The process is transparent, optimized and yields high returns with good fill% that our guarantee.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Our 24/7 support and real-time tracking ensures that campaign traffic is optimized for acquisitions, yet within budget. We make digital advertising effortlessly profitable for our publishers.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" />Flexible campaign options to get the best price for premium inventory via RTB, PMP, Deal IDs, Automated Guaranteed, and Direct campaigns.All impression are audited by best fraud detection tools.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" />All our financial transactions are quick and hassle-free. We believe in efficiency and a quick turnaround time. And that means, no worries over delayed payments.</Typography>
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>
                        <Grid item style={{ width: "95%", marginLeft: "10px", marginBottom: "50px" }}>
                            <OurProcess screenSize={props.screenSize} />
                        </Grid>
                    </Grid>
                </Item>
                <ContactUs screenSize={props.screenSize} />
                <Footer screenSize={props.screenSize} />
            </>
        );

    } else {
        return (
            <>
                <Header screenSize={props.screenSize} />
                <Item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "25px", marginTop: "15px", height: "7.5vh", fontWeight: "550", textTransform: "inherit", fontFamily: "system-ui", color: "#026a9b" }}>PUBLISHER</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}>Our exclusive technological solutions for publishers and digital media channels, provide you with an ideal tool to increase profits, and obtain greater value from your advertising space. With 2 billion ad impressions every day, we are able to guarantee you a best fill rate across all geographic regions.</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6}>
                                <Box component="img" sx={{ height: "50vh" }} src={b3} />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "50px", fontSize: "larger", fontWeight: "550" }}>From our powerful, self ­service platform to our Premium Service of media buying ­ select the option that is best for you – and start to monetize.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(I) </Box> Setting floors and manage residual inventory.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(II) </Box> Best fill rates and CPM.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(III) </Box> Quality control in terms of including/excluding categories,brands,technical attributes, specific advertisers, brands.</Typography>
                                <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left" }}><Box fontWeight='fontWeightMedium' display='inline'>(IV) </Box> Brand safety as we work on themediatrust.com and manage all ads for malware protection, brand safety,etc.</Typography></Grid>
                        </Grid>
                        <Grid item style={{ width: "85vw", marginLeft: "100px" }}>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginTop: "100px", fontSize: "x-large", fontWeight: "550" }}>Our Work Culture</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Our advertising campaigns cut through the all the clutter of Mobile and Desktop advertising and are centered on performance. We route traffic with direct offers to different markets and segments to deliver the best cost per acquisition/install/sale/Impression performance. The process is transparent, optimized and yields high returns with good fill% that our guarantee.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" /> Our 24/7 support and real-time tracking ensures that campaign traffic is optimized for acquisitions, yet within budget. We make digital advertising effortlessly profitable for our publishers.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" />Flexible campaign options to get the best price for premium inventory via RTB, PMP, Deal IDs, Automated Guaranteed, and Direct campaigns.All impression are audited by best fraud detection tools.</Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontFamily: "system-ui", textAlign: "left", marginLeft: "30px" }}><FiberManualRecordIcon fontSize="1px" />All our financial transactions are quick and hassle-free. We believe in efficiency and a quick turnaround time. And that means, no worries over delayed payments.</Typography>
                            <Divider variant="middle" sx={{ marginTop: "50px" }} />
                        </Grid>
                        <Grid item style={{ width: "85vw", marginLeft: "100px", marginBottom: "50px" }}>
                            <OurProcess screenSize={props.screenSize} />
                        </Grid>
                    </Grid>
                </Item>
                <ContactUs />
                <Footer screenSize={props.screenSize} />
            </>
        );
    }
}


export default Publisher;